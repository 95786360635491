// This plugin is currently not used directly but will be in the future probably.
// Adding the plugin files here as it's deleted from the github repo.

import { createApp } from 'vue';
import App from './App.vue';

import {
  getImages,
  getImagesMockup,
  itemsFilesToCarouselImages,
} from './utils/o365-api';
import { CarouselImage } from './components/Carousel.vue';

function renderCarousel() {
  const elements = Array.from(document.getElementsByClassName('o365-carousel'));

  elements.forEach(async (e) => {
    let props = e.dataset;
    let images = [];
    let culture =
      document.documentElement.lang && document.documentElement.lang != ''
        ? document.documentElement.lang
        : undefined;

    images = itemsFilesToCarouselImages(
        await getImages(props.contentItemId, culture, props.galleryId)
    );

    createApp(App, {
      images,
    }).mount(e);
  });
}

window.onload = renderCarousel;